import {
	GET_PRODUCTS,
	GET_ALL_PRODUCTS,
	GET_MEMBERSHIP,
	GET_PRODUCT_DETAILS,
	GET_PRODUCT_GROUPS,
	GET_MANY_PRODUCT_DETAILS,
	GET_ACCOUNT_TRANSFER_FEE,
	GET_PROMOTION_DETAILS,
	GET_PROMOTIONS,
	GET_FREE_VIRTUAL_EVENTS,
	GENERATE_FREE_VIRTUAL_EVENT_TICKET,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class AutoshipInformation {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getProducts(countryCode, type, options) {
		const category = type;
		const country = countryCode;
		let data = { country };
		if (typeof category !== 'undefined') {
			data.category = category;
		}

		if (typeof countryCode === 'undefined' || countryCode === 'membership') {
			console.warn('error in country');
			console.trace();
		}

		if (typeof options !== 'undefined') {
			data = { ...data, ...options };
		}

		const { method, endpoint } = GET_PRODUCTS;

		this.options.data = data;
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllProducts(options = {}) {
		const { method, endpoint } = GET_ALL_PRODUCTS;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getProductGroups() {
		const { method, endpoint } = GET_PRODUCT_GROUPS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getMembership(countryCode) {
		const country = countryCode;
		const data = { country };
		if (typeof countryCode === 'undefined') {
			console.warn('error in country');
			console.trace();
		}

		const { method, endpoint } = GET_MEMBERSHIP;

		this.options.data = data;
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAccountTransferFee(countryCode) {
		const country = countryCode;
		const data = { country };
		if (typeof countryCode === 'undefined') {
			console.warn('error in country');
			console.trace();
		}

		const { method, endpoint } = GET_ACCOUNT_TRANSFER_FEE;

		this.options.data = data;
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getProductDetails(productName) {
		const { method, endpoint } = GET_PRODUCT_DETAILS;
		return this.data[method](endpoint(productName)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getManyProductDetails(options) {
		const { method, endpoint } = GET_MANY_PRODUCT_DETAILS;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPromotionDetails(promoName) {
		const { method, endpoint } = GET_PROMOTION_DETAILS;
		return this.data[method](endpoint(promoName)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPromotions() {
		const { method, endpoint } = GET_PROMOTIONS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getFreeVirtualEvents() {
		const { method, endpoint } = GET_FREE_VIRTUAL_EVENTS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	generateFreeVirtualEventTicket(payload) {
		const { method, endpoint } = GENERATE_FREE_VIRTUAL_EVENT_TICKET;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default AutoshipInformation;
